import dynamic from 'next/dynamic';
import React, {useContext, useRef} from 'react';
import useOnScreen from 'aac-components/utils/hooks/useOnScreen';
import AppContext from '../AppContext';
import {getGeoTargetFacilityData} from 'aac-components/components/GeoTargetDrawerContent/helpers';

const GeoTargetMap = dynamic(() =>
    import('aac-components/components/GeoTargetMap/index'),
);

export const customMapLinks = {
    ADC: {
        title: 'AdCare Treatment Hospital: Worcester, MA',
        url: '/treatment-centers/adcare-hospital',
    },
    DSH: {
        title: 'Desert Hope Treatment Center: Las Vegas, NV',
        url: '/treatment-centers/desert-hope',
    },
    GRN: {
        title: 'Greenhouse Treatment Center: Grand Prairie, TX',
        url: '/treatment-centers/greenhouse',
    },
    LAG: {
        title: 'Laguna Treatment Center: Aliso Viejo, CA',
        url: '/treatment-centers/laguna-treatment-center',
    },
    REF: {
        title: 'Recovery First Treatment Center: Hollywood, FL',
        url: '/treatment-centers/recovery-first-hollywood',
    },
    ROA: {
        title: 'River Oaks Treatment Center: Riverview, FL',
        url: '/treatment-centers/river-oaks',
    },
    XFO: {
        title: 'Oxford Treatment Center: Etta, MS',
        url: '/treatment-centers/oxford',
    },
};

const GeoTargetMapWrapper = () => {
    const {sessionStorageState} = useContext(AppContext);
    const geolocation = sessionStorageState.geolocation || {};

    const ref = useRef();
    const onScreen = useOnScreen(ref, '500px'); // within 500px of CTA

    // get geotarget facility data if there's a match
    const matchFacility =
        Object.keys(geolocation).length !== 0 && geolocation?.facility
            ? getGeoTargetFacilityData(geolocation.facility)
            : null;

    return (
        <div id="geo-map" ref={ref}>
            {onScreen && (
                <GeoTargetMap
                    facilityKey={matchFacility?.siteKey}
                    highlightAll={true}
                    customLinks={customMapLinks}
                />
            )}
        </div>
    );
};
export default GeoTargetMapWrapper;
